import { HttpClient } from "@angular/common/http";
import { Ifeatureflagconfig } from "./featureflag.interface";
import { featureFlagConstant } from "./featureFlagConstant"
import { Injectable } from "@angular/core";
import {IFeatureFlagService} from "./IFeatureFlagService.interface";

@Injectable({ providedIn: 'root' })
export class FeatureFlagJsonService implements IFeatureFlagService {
  constructor(private http: HttpClient) {}
  public getFlags() {
    const jsonFile = window.location.hostname === "localhost" ? featureFlagConstant.FeatureFlag_JsonPath_local : featureFlagConstant.FeatureFlag_JsonPath_server;
    return new Promise<Ifeatureflagconfig[]>((resolve, reject) => {
      this.http
        .get(jsonFile)
        .toPromise()
        .then((response: Ifeatureflagconfig[]) => {
          console.log('using the local feature flag service');
          resolve(response);
        })
        .catch((response: any) => {
          reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
        });
    });
  }
}
