import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonService } from '../services/common.service';
import { AppUtilityService } from '../services/utility.service';
import { environment } from '../../environments/environment';
import { InvoiceConstants } from '../media-management/invoices/invoice.constants';
import { BehaviorSubject } from 'rxjs';
import { ExportExcelService } from './exportExcel.service';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {
    public disableListBtn = new BehaviorSubject(false);
    public resetFiles = new BehaviorSubject(false);
    public showCancel = new BehaviorSubject(false);
    //private MOCK_INVOICE_JSON_URL: string = '../assets/jsons/invoices-upload.json?v=' + Math.random();
    private MOCK_INVOICE_JSON_URL = '../assets/jsons/invoices-upload.json';
    private MOCK_REVIEW_JSON_URL = '../assets/jsons/invoices-review.json';
    private MOCK_INVOICE_VIEW_JSON_URL = '../assets/jsons/invoices-view.json';
    private MOCK_APPROVE_JSON_URL = '../assets/jsons/invoices-approve.json';
    private MOCK_DIR_REVIEW_JSON_URL = '../assets/jsons/invoices-director-review.json';
    private MOCK_FIN_REVIEW_JSON_URL = '../assets/jsons/invoices-finance-review.json';
    private MOCK_SNAP_REVIEW_JSON_URL = '../assets/jsons/invoices-snap-review.json';
    private MOCK_DECLINE_JSON_URL = '../assets/jsons/invoices-decline.json';
    private MOCK_INVOICE_BALANCE_JSON_URL = '../assets/jsons/invoice-balance.json';
    dataForExcel = [];
    invoiceMasterData: any;
    invoiceCampaignList:any;
    invoiceMediaType:any;
    invoiceNetwork : any;
    public stopProgressLoader   = new BehaviorSubject(false);
    //MAFN-238
    public addInvoiceTempFlag = new BehaviorSubject(false);

    constructor(private http: HttpClient, private cS: CommonService, private eES: ExportExcelService,private eUS:AppUtilityService) {
        // this.fetchInvoicesMasterData();
    }

    uploadInvoices(payLoad) {
       const url = environment.basePath + InvoiceConstants.apiUrl.uploadInvoices;
        //return this.cS.serviceRequestCommon('get', this.MOCK_INVOICE_JSON_URL);
        return this.cS.post(url, payLoad);
    }
    fetchReviewData() {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.mediaReviewInvoices;
        //const url = this.MOCK_REVIEW_JSON_URL;
        return this.cS.serviceRequestCommon('get', url);
        //return this.cS.serviceRequestCommon('get', this.MOCK_REVIEW_JSON_URL);
    }
    // fetchReviewData() {
    //     return this.cS.serviceRequestCommon('get', this.MOCK_REVIEW_JSON_URL);
    // }

    fetchDirReviewData() {
        //return this.cS.serviceRequestCommon('get', this.MOCK_DIR_REVIEW_JSON_URL);
       const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.directorReviewInvoices;
        return this.cS.serviceRequestCommon('get', url);
    }
    fetchMediaTypeData() {
      const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.fetchMediaTypeMasterData;
     this.cS.serviceRequestCommon('get', url).subscribe(res => {
      this.invoiceMediaType = res;
     })

    }

    fetchNetworkData() {
        const url = environment.adminBasePath + InvoiceConstants.apiUrl.networkList;
        return this.cS.serviceRequestCommon('get', url).subscribe(res => {
          this.invoiceNetwork =  res["networkList"];
         })
      }


    fetchShowNameMasterData() {
      const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.fetchShowNameMasterData;
      this.cS.serviceRequestCommon('get', url).subscribe(res => {
        this.invoiceCampaignList = res;
    })
  }

    fetchFinReviewData() {
        //return this.cS.serviceRequestCommon('get', this.MOCK_FIN_REVIEW_JSON_URL);
         const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.FinanceReviewInvoices;
        return this.cS.serviceRequestCommon('get', url);
    }

    fetchSnapReviewData() {
        //return this.cS.serviceRequestCommon('get', this.MOCK_SNAP_REVIEW_JSON_URL);
         const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.snapReviewInvoices;
        return this.cS.serviceRequestCommon('get', url);
    }

    fetchApproveData(payLoad) {
       // return this.cS.serviceRequestCommon('get', this.MOCK_APPROVE_JSON_URL);
         const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.approveInvoices;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    fetchRejectData(payLoad, sso) {
      // return this.cS.serviceRequestCommon('get', this.MOCK_APPROVE_JSON_URL);
      const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.rejectInvoices;
      return this.cS.serviceRequestCommon('post', url, sso, payLoad);
    }

    fetchDeclineData(payLoad) {
        //return this.cS.serviceRequestCommon('get', this.MOCK_DECLINE_JSON_URL);
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.declineInvoices;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    fetchIOData(showName,mediaType,month,Year) {

        const url = environment.invoiceBasePath +  this.eUS.formatString(InvoiceConstants.apiUrl.fetchIOData,showName,mediaType,month,Year);
        return this.cS.serviceRequestCommon('get', url);
    }


    fetchInvoiceDetails(invoiceNumber, screen) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.fetchInvoiceDetails + invoiceNumber + '?screen=' + screen;
        return this.cS.serviceRequestCommon('get', url);
    }

    fetchInvoicesMasterData() {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.fetchInvoicesMasterData;
        this.cS.serviceRequestCommon('get', url).subscribe(res => {
            this.invoiceMasterData = res;
        })
    }



    importInvoices(payLoad) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.importInvoices;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    cancelImportInvoices(transactionId) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.cancelImportInvoices + transactionId;
        return this.cS.serviceRequestCommon('get', url);
    }

    createInvoice(payLoad) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.createInvoice;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    updateInvoice(payLoad, invoiceNo, screen) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.updateInvoice + invoiceNo + '?screen=' + screen;
        return this.cS.serviceRequestCommon('put', url, '', payLoad);
    }

    preSignedPutFile(payLoad) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.preSignedPutFile;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    uploadfileAWSS3(fileuploadurl, contenttype, file) {
        return this.cS.uploadfileAWSS3(fileuploadurl, contenttype, file);
    }


    preSignedGetFile(fileName) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.preSignedGetFile + fileName;
        //return this.cS.preSignedGetFile(url);
         return this.cS.serviceRequestCommon('get', url);
    }

    downloadPdfFile(url) {
      return this.cS.downloadPdfFile(url);
  }

    deleteInvoice(payLoad, screen) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.deleteInvoice + '?screen=' + screen;
        return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    fetchInvoicesByTransactionId(transactionId) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.fetchInvoicesByTransactionId + transactionId;
        return this.cS.serviceRequestCommon('get', url);
    }

    fetchInvoiceHistory() {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.fetchInvoiceHistory;
        return this.cS.serviceRequestCommon('get', url);
    }

    submitToSNAP(payLoad){
      const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.submitToSNAP;
      // console.log('submitToSNAP' + url, payLoad);
      //return this.cS.serviceRequestCommon('get', this.MOCK_SNAP_JSON_URL);
      return this.cS.serviceRequestCommon('post', url, '', payLoad);
    }

    fetchApprovalHistory(invoiceNumber) {
        const url = environment.invoiceBasePath + InvoiceConstants.apiUrl.approvalHistory + invoiceNumber;
        return this.cS.serviceRequestCommon('get', url);
    }

    fetchInvoiceBalanceDetails(payLoad){
      const url = environment.invoiceBasePath +  this.eUS.formatString(InvoiceConstants.apiUrl.balanceInformation,payLoad.showId,payLoad.reviewState);
      return this.cS.serviceRequestCommon('get', url);
    }

    fetchTemplate() {
        const url = environment.basePath + InvoiceConstants.apiUrl.downloadTemplate;
        this.cS.downloadTemplate(url).subscribe((res: HttpResponse<any>) => {
          const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
          const a = document.createElement('a');
          const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          a.href = URL.createObjectURL(file);
          a.download = filename;
          a.click();
          // this.outputElem.nativeElement.click();
        });
      }

    exportDownloadHandlerService(reviewState) {
        const url = environment.basePath + InvoiceConstants.apiUrl.exportInvoices + reviewState;
        this.cS.fileDownloadWithoutPayload(url).subscribe((res: HttpResponse<any>) => {
            const filename = res.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');

            const a = document.createElement('a');
            const file = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            a.href = URL.createObjectURL(file);
            a.download = filename;
            a.click();
            this.stopProgressLoader.next(true);
        }, err => {
            this.stopProgressLoader.next(true);
        });
    }

    exportHandlerService(data) {
        //MAFN-256 - Fix for duplicate export records
        this.dataForExcel = [];

        data.forEach((row: any) => {
            delete row.showId;
            delete row.genreNo;
            delete row.genreCode;
            delete row.oldComments;
            //MAFN-256 - Fix for left align - START
            delete row.validations;
            if( row.invoiceAmount !== undefined ) row.invoiceAmount = ""+row.invoiceAmount;
            if( row.ioId !== undefined ) row.ioId = ""+row.ioId;
            //MAFN-256 - Fix for left align - END
            this.dataForExcel.push(Object.values(row));
        });

        const headers = [{ name: 'Invoice #', width: 15 }, { name: 'Vendor', width: 18 }, { name: 'Invoice Date', width: 11 },
        { name: 'Invoice Amount', width: 15 }, { name: 'Status', width: 17 }, { name: 'Posting Date', width: 13 },
        { name: 'Clearing Date', width: 13 }, { name: 'IO #', width: 10 },
        { name: 'Media Type', width: 12 }, { name: 'Media Sub-Type', width: 15 },
        { name: 'Company Code', width: 14 }, { name: 'GL Account', width: 14 }, { name: 'WBS Element', width: 15 }
        ];

        // new Date('MM-DD-YYYY-HH-MM-ss-a')
        const now = moment().format('MM-DD-YYYY-hh-mm-ss-A');

        const reportData = {
            title: 'Export_InvoiceHistory_Table_' + now,
            data: this.dataForExcel,
            headers,
            worksheetName: 'Invoice History Data'
        };
        this.eES.exportExcel(reportData);
    }

    addInvoice = {
        'transactionId': 1,
        'invoiceList': [
            {
                'invoicesId': 'NT06-051625-1',
                'vendorName': 'MediaVest',
                'invoiceDate': '6/5/2020',
                'invoiceAmount': 25000,
                'validations': [
                    {
                        'message': 'This invoice is associated with invalid IO',
                        'type': 'H'
                    },
                    {
                        'message': 'Invalid Amount',
                        'type': 'H'
                    },
                    {
                        'message': 'This invoice is missing an attachement',
                        'type': 'S'
                    }
                ],
                'ioList': [
                    {
                        'ioId': 1001,
                        'showName': '',
                        'mediaType': '',
                        'mediaSubType': '',
                        'companyCode': '',
                        'glAccountNumber': 0,
                        'wbsElement': '',
                        'amount': 0,
                        'notes': '',
                        'isHardWarning': true,
                        'errorMessages': 'Invalid IO#'
                    },
                    {
                        'ioId': 1002,
                        'showName': 'Brooklyn',
                        'mediaType': 'Television',
                        'mediaSubType': 'Local Cable',
                        'companyCode': 'BA01',
                        'glAccountNumber': 11223344,
                        'wbsElement': 'Multiple Months',
                        'amount': 10000,
                        'notes': 'Lorem Ipsum',
                        'isHardWarning': false,
                        'errorMessages': ''
                    }
                ]
            },
            {
                'invoicesId': 'NT06-051625-2',
                'vendorName': 'MediaVest',
                'invoiceDate': '6/5/2020',
                'invoiceAmount': 25000,
                'softWarnings': [],
                'hardWarnings': [],
                'ioList': [
                    {
                        'ioId': 1003,
                        'showName': 'Brooklyn',
                        'mediaType': 'Television',
                        'mediaSubType': 'Local Cable',
                        'companyCode': 'BA01',
                        'glAccountNumber': 11223344,
                        'wbsElement': 'GM20124701',
                        'amount': 1000000,
                        'notes': 'Lorem Ipsum',
                        'isHardWarning': false,
                        'errorMessages': ''
                    },
                    {
                        'ioId': 1004,
                        'showName': 'Brooklyn',
                        'mediaType': 'Television',
                        'mediaSubType': 'Local Cable',
                        'companyCode': 'BA01',
                        'glAccountNumber': 11223344,
                        'wbsElement': 'GM20124701',
                        'amount': 10000,
                        'notes': 'Lorem Ipsum',
                        'isHardWarning': false,
                        'errorMessages': ''
                    }
                ]
            }
        ],
        'validationMessage': [
            {
                "message": "2 PDF document does not have a corresponding invoice.",
                "type": "H"
            },
            {
                "message": "1 invoice has missing or invalid information",
                "type": "H"
            },
            {
                "message": "1 invoice does not have a corresponding PDF document",
                "type": "S"
            }
        ]
    };

    invoiceData = {
        "vendorId": 1,
        "vendorAccountNo": 12345,
        "invoiceDate": "6/5/2020",
        "companyCode": "BA01",
        "incomeType": "Services",
        "invoiceAmount": 300000,
        "taxAmount": 10000,
        "grossAmount": 400000,
        "currency": "USD",
        "description": "Test desciption",
        "stateID": 1,
        "state": "CA",
        "zip": 37069,
        "status": "Invoice Import",
        "ioList": [
            {
                "ioId": 1001,
                "lineItem": 436476,
                "amount": 1000000,
                "coCode": "B0A1",
                "generalLedgerAccount": "Test",
                "isTaxable": "Y",
                "wbsElement": ["GM20124701", "GM20124702"],
                "lineItemDescription": "Test Line Item description"
            }
        ],
        "attachements": [
            {
                "id": "1",
                "attachmentFileName": "example.pdf",
                "url": "https://example.com/1.pdf",
                "isDeleted": false
            }
        ],
        "snapInformation": {
            "instanceID": 1,
            "statusCode": "A",
            "statusDecription": "Lorem Ipsum",
            "accountingDocumentNumber": 1234,
            "documnetType": "pdf",
            "postingDate": "6/5/2020",
            "clearingDate": "6/5/2020",
            "paymentMethod": "CC"
        },
        "changeHistory": [
            {
                "changedOnDate": "6/5/2020",
                "changedBy": "James",
                "comments": "Approved for Directory Review"
            },
            {
                "changedOnDate": "7/7/2020",
                "changedBy": "Biren",
                "comments": "Approved for Directory Review"
            }
        ]
    }

    ioData = {
        "ioId": 1001,
        "lineItem": 536476,
        "amount": 1000001,
        "coCode": "B0A2",
        "generalLedgerAccount": "12345679",
        "wbsElement": ["GM20124703", "GM20124704"],
        "isTaxable": "N",
        "lineItemDescription": "Test Line Item description 2"
    }

    invoiceHistory = {
        "invoiceList": [
            {
                "invoicesId": "NT06-051625-1",
                "vendor": "Media Vest",
                "invoiceDate": "6/5/2020",
                "invoiceAmount": 25000,
                "status": "Media Coordinator",
                "postingDate": "6/4/2020",
                "clearingDate": "6/6/2020",
                "ioId": 1001,
                "mediaType": "Television",
                "mediaSubType": "Local Cable",
                "companyCode": "BA01",
                "glAccountNumber": 11223344,
                "wbsElement": "GM20124701"
            },
            {
                "invoicesId": "NT06-051625-2",
                "vendor": "Media Vest",
                "invoiceDate": "5/5/2020",
                "invoiceAmount": 30000,
                "status": "Director Review",
                "postingDate": "5/1/2020",
                "clearingDate": "5/10/2020",
                "ioId": 1002,
                "mediaType": "Television",
                "mediaSubType": "Local Cable",
                "companyCode": "BA02",
                "glAccountNumber": 112244,
                "wbsElement": "GM20124702"
            },
            {
                "invoicesId": "NT06-051625-1",
                "vendor": "Media Vest",
                "invoiceDate": "6/5/2020",
                "invoiceAmount": 25000,
                "status": "Media Coordinator",
                "postingDate": "6/4/2020",
                "clearingDate": "6/6/2020",
                "ioId": 1001,
                "mediaType": "Television",
                "mediaSubType": "Local Cable",
                "companyCode": "BA01",
                "glAccountNumber": 11223344,
                "wbsElement": "GM20124701"
            },
            {
                "invoicesId": "NT06-051625-2",
                "vendor": "Media Vest",
                "invoiceDate": "5/5/2020",
                "invoiceAmount": 30000,
                "status": "Director Review",
                "postingDate": "5/1/2020",
                "clearingDate": "5/10/2020",
                "ioId": 1002,
                "mediaType": "Television",
                "mediaSubType": "Local Cable",
                "companyCode": "BA02",
                "glAccountNumber": 112244,
                "wbsElement": "GM20124702"
            },
            {
                "invoicesId": "NT06-051625-1",
                "vendor": "Media Vest",
                "invoiceDate": "6/5/2020",
                "invoiceAmount": 25000,
                "status": "Media Coordinator",
                "postingDate": "6/4/2020",
                "clearingDate": "6/6/2020",
                "ioId": 1001,
                "mediaType": "Television",
                "mediaSubType": "Local Cable",
                "companyCode": "BA01",
                "glAccountNumber": 11223344,
                "wbsElement": "GM20124701"
            },
            {
                "invoicesId": "NT06-051625-2",
                "vendor": "Media Vest",
                "invoiceDate": "5/5/2020",
                "invoiceAmount": 30000,
                "status": "Director Review",
                "postingDate": "5/1/2020",
                "clearingDate": "5/10/2020",
                "ioId": 1002,
                "mediaType": "Television",
                "mediaSubType": "Local Cable",
                "companyCode": "BA02",
                "glAccountNumber": 112244,
                "wbsElement": "GM20124702"
            }
        ]
    };
}
