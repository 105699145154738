import {Inject,Injectable} from "@angular/core";
import { IFeatureFlagService } from "./IFeatureFlagService.interface";
import { BehaviorSubject } from "rxjs";
import { Ifeaturedata } from "./featureflag.interface"
import { FeatureFlagJsonService } from "./featureflag.json.service";
import { environment } from 'src/environments/environment';
import { resolve } from "path";

@Injectable({ providedIn: 'root' })
export class FeatureFlagService{
  public static _featureFlags: Ifeaturedata[];
  private featureFlagImpl :IFeatureFlagService;
  private updateFeatureFlags$ = new BehaviorSubject(null);

  constructor(
    featureFlagJsonService:FeatureFlagJsonService
  )
  {
    this.featureFlagImpl =featureFlagJsonService;
  }

  IsFeatureOn(featureName:string):boolean {
    if(!featureName){
      return true;
    }
    return FeatureFlagService._featureFlags && FeatureFlagService._featureFlags[featureName] && FeatureFlagService._featureFlags[featureName]["enabled"] === true;
  }

 public async load(): Promise<any> {
    await this.featureFlagImpl
      .getFlags()
      .then((result) => {
        // // TODO: Remove this check once AppCOnfig update for NON-Dev Environment
        if (!environment.enableAppConfig) {
          result.find((setting) => {
            if (setting.env === environment.environmentName) {
              this.updateFeatureFlagConfig(setting.features);
            }
          });
        } else {
          this.updateFeatureFlagConfig(result);
        }
      })
      .catch(() => {});
  }

  private updateFeatureFlagConfig(config: any): void {
    FeatureFlagService._featureFlags = config;
    this.updateFeatureFlags$.next(config);
  }
}
