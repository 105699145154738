export const InvoiceConstants = {
    apiUrl: {
        uploadInvoices: 'aspose/uploadInvoice',
        importInvoices: 'invoices/import',
        cancelImportInvoices: 'invoices/cancel/',
        mediaReviewInvoices: 'invoices/review/M',
        networkList: 'admin/networks',
        directorReviewInvoices: 'invoices/review/D',
        FinanceReviewInvoices: 'invoices/review/F',
        snapReviewInvoices: 'invoices/review/S',
        createInvoice: 'invoices/invoice',
        fetchInvoiceDetails: 'invoices/invoice/',
        updateInvoice: 'invoices/invoice/',
        deleteInvoice: 'invoices/delete',
        fetchIOData: 'invoices/accountInfo/show/{0}/mediaType/{1}/month/{2}/year/{3}',
        fetchInvoicesMasterData: 'invoices/lov',
        fetchShowNameMasterData: 'campaign/load',
        fetchMediaTypeMasterData: 'campaign/lov',
        fetchInvoicesByTransactionId: 'invoices/',
        approveInvoices: 'invoices/approve',
        declineInvoices: 'invoices/decline',
        exportInvoices: 'aspose/exportInvoice/',
        preSignedPutFile: 'invoices/upload/url',
        preSignedGetFile: 'invoices/document/url/',
        rejectInvoices: 'invoices/decline?sso=',
        fetchInvoiceHistory: 'invoices/history',
        submitToSNAP: 'invoices/submitToSNAP',
        approvalHistory: 'invoices/approvalHistory/',
        downloadTemplate: 'invoices/template',
        balanceInformation: 'invoices/balanceInformation/{0}?reviewState={1}'

    },
      deleteOperation: {
        name: 'deleteInvoice',
        title: 'Delete Invoice Record',
        popupWidth :'372px',
        height:'auto'
      },
      changeNetwork: {
        name: "changeNW",
        title: "Network Selection",
        popupWidth :'500px',
        height:'auto'
      }

};
